<template>
    <div style="background-color: white;padding: 50px">
        <a-spin :tip="tip" :spinning="show" :delay="delayTime">
            <div class="c-title"><span style="background-color: #1890ff">&nbsp</span> 基本信息</div>
            <a-form-model ref="ruleForm" v-bind="formItemLayout" :model="form"  :rules="rules">
            <div style="margin-top: 20px;">
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="客户名称" prop="nickname">
                            <a-input v-model="form.nickname" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="指定性别" prop="sex" has-feedback>
                            <a-select v-model="form.sex" placeholder="选择">
                                <a-select-option :value="item.value" v-for="item in sex">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="出生日期" prop="birthday" >
                            <a-date-picker v-model="form.birthday" show-time format="YYYY-MM-DD" @change="onChangeDate"/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="所属中心" prop="schoolId" has-feedback>
                            <a-select v-model="form.schoolId" placeholder="选择">
                                <a-select-option :value="item.value" v-for="item in schools">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-model-item label="客户来源"  prop="source"  has-feedback>
                            <a-select v-model="form.source" placeholder="选择" >
                                <a-select-option :value="item.value" v-for="item in sources">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="有意向度"  prop="intention">
                            <a-input v-model="form.intention" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>

                    <a-col :span="8">
                        <a-form-model-item label="指定活动"  prop="JoinActivity">
                            <a-select v-model="form.joinActivity" placeholder="选择" >
                                <a-select-option :value="item.value" v-for="item in joinActivitys">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="填写备注">
                            <a-input v-model="form.remark" type="textarea" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </div>
            <div class="c-title"><span style="background-color: #1890ff">&nbsp</span> 联系人信息</div>
            <div style="margin-top: 20px;">
                    <a-row v-for="(item,index) in contacts">
                        <a-col :span="8">
                            <a-form-model-item label="主联系人" style="display: flex;flex-direction: row" layout="inline" has-feedback>
                                <a-select v-model="item.is_contacts" >
                                    <a-select-option :value="item.value" v-for="item in isContacts">
                                        {{item.label}}
                                    </a-select-option>
                                </a-select>
                                <a-select  v-model="item.relationship">
                                    <a-select-option :value="item.value" v-for="item in relationships">
                                        {{item.label}}
                                    </a-select-option>
                                </a-select>
                                <a-input v-model="item.parentName" placeholder="联系人称呼"  />
                                <a-input v-model="item.phone"   placeholder="联系人电话" />
                                <div :style="{'width': '0px','position': 'relative','top': '-5px'}" >
                                <a-icon v-if="index!=contacts.length-1" class="dynamic-delete-button" type="minus-circle-o" @click="removeContacts(item)"/>
                                </div>
                                <div :style="{'width': '0px','position': 'relative','top': '-5px'}" >
                                    <a-button style="margin-left: 10px" @click="addDomain" type="primary" v-if="index==contacts.length-1">添加联系人</a-button>
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
            </div>
            <div class="c-title"><span style="background-color: #1890ff">&nbsp</span> 联系地址</div>
            <a-row>
                <a-col :span="8">
                    <div style="display: flex;flex-direction: column;padding-left: 115px;padding-top: 0px;">
                        <div style="margin-bottom: 20px">
                            <a-cascader
                                    style="margin-top: 1rem"
                                    :options="options"
                                    placeholder="请选择省市区"
                                    @change="onChangeAddress"
                            />
                        </div>
                        <div style="margin-bottom: 20px">
                            <a-textarea
                                    placeholder="请输入详细地址"
                                    :auto-size="{ minRows: 5, maxRows: 10 }"
                            />
                        </div>

                    </div>
                </a-col>
            </a-row>
            <div class="c-title"><span style="background-color: #1890ff">&nbsp</span> 服务人员</div>
            <div style="left: -20px;top: 10px;position: relative">
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="顾问" prop="adviserId">
                            <a-select  mode="multiple"
                                    v-model="form.adviserId"
                                    style="width: 100%"
                                    placeholder="选择顾问"
                            >
                                <a-select-option :value="item.value" v-for="item in joinActivitys">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="市场">
                            <a-select  mode="multiple"
                                    v-model="form.marketId"
                                    style="width: 100%"
                                    placeholder="选择市场员"
                            >
                                <a-select-option :value="item.value" v-for="item in joinActivitys">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="TMK">
                            <a-select  mode="multiple"
                                    v-model="form.tmkId"
                                    style="width: 100%"
                                    placeholder="选择TMK"
                            >
                                <a-select-option :value="item.value" v-for="item in joinActivitys">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item label="客服">
                            <a-select  mode="multiple"
                                    v-model="form.serviceId"
                                    style="width: 100%"
                                    placeholder="选择客服"
                            >
                                <a-select-option :value="item.value" v-for="item in joinActivitys">
                                    {{item.label}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-form-model-item :wrapper-col="{ xs: { span: 24, offset: 0 },sm: { span: 24, offset: 10 }}">
                    <a-button type="primary" @click="submit">
                        提交
                    </a-button>
                    <a-button type="danger" style="margin-left: 20px">
                        取消
                    </a-button>
                </a-form-model-item>
            </div>
            </a-form-model>
        </a-spin>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                options: [
                    {
                        value: 'zhejiang',
                        label: 'Zhejiang',
                        children: [
                            {
                                value: 'hangzhou',
                                label: 'Hangzhou',
                                children: [
                                    {
                                        value: 'xihu',
                                        label: 'West Lake',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        value: 'jiangsu',
                        label: 'Jiangsu',
                        children: [
                            {
                                value: 'nanjing',
                                label: 'Nanjing',
                                children: [
                                    {
                                        value: 'zhonghuamen',
                                        label: 'Zhong Hua Men',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                tip:'加载中...',
                show:false,
                delayTime:100,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 8 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 16 },
                    },
                },

                sex:[
                    {label:'男',value:1},
                    {label:'女',value:2},
                ],
                schools:[
                    {label:'深圳罗湖',value:1},
                    {label:'深圳宝安',value:2},
                ],
                joinActivitys:[
                    {label:'户外',value:1},
                    {label:'室内',value:2},
                ],
                sources:[
                    {label:'思可学院',value:1},
                    {label:'总部网络',value:2},
                ],
                isContacts:[
                    {label:'否',value:1},
                    {label:'是',value:2},
                ],
                contacts:[{
                    parentName:'',
                    phone:'',
                    relationship:'',
                    is_contacts:1,
                }],
                relationships:[
                    {label:'爸爸',value:1},
                    {label:'妈妈',value:2},
                    {label:'哥哥',value:3},
                ],
                form: {
                    nickname: '',
                    sex:'',
                    birthday:'',
                    schoolId:'',
                    intention:'',
                    source:'',
                    remark:'',
                    joinActivity:'',
                    adviserId:[],
                    serviceId:[],
                    marketId:[],
                    tmkId:[],
                    contacts:[],
                },
                rules: {
                    nickname: [
                        { required: true, message: '请输入客户名称', trigger: 'blur' },
                    ],
                    birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
                    sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
                    schoolId: [{ required: true, message: '请选择中心', trigger: 'change' }],
                    relationship0: [{ required: true, message: '请选择主联系人', trigger: 'blur' }],
                    adviserId: [
                        {
                            type: 'array',
                            required: true,
                            message: '请选择顾问',
                            trigger: 'change',
                        },
                    ],
                    source: [
                        { required: true, message: '请选择客户来源', trigger: 'change' },
                    ],
                },
            };
        },
        methods: {
            onChangeAddress(value) {
                console.log(value);
            },
            onChangeDate(date,dateString) {
                let that = this;
                that.form.birthday= dateString
            },
            addDomain() {
                this.contacts.push({
                    is_contacts:1,
                    parentName:'',
                    phone:'',
                    relationship:'',
                });
            },
            submit(){
                let that =this;
                that.form.contacts = that.contacts;
                //前端表单验证1
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        that.show = true
                        //验证通过发送数据请求
                        alert('submit!');
                        console.log(that.form)
                        const axios = require('axios');
                        axios.get('https://customer.com/api/student')
                            .then(function (response) {
                                // 处理成功情况
                                if(response.data.code==200){
                                    that.$message.success('新增成功');
                                    that.show=false;
                                }
                            })
                            .catch(function (error) {
                                // 处理错误情况
                                console.log(error);
                            })
                            .then(function () {
                                // 总是会执行
                            });
                    } else {
                        //验证未通过
                        console.log('error submit!!');
                        return false;
                    }
                });


            },
            handleChange(value){
                console.log(`selected ${value}`);
            },
            handleSubmit(){

            },
            removeContacts(item) {
                let index = this.contacts.indexOf(item);
                if (index !== -1) {
                    this.contacts.splice(index, 1);
                }
            },

        },
    }
</script>

<style scoped>
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
        left: 8px;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .c-title{
        font-weight: 600;
        font-size: 17px;
    }
    /deep/.ant-form-item-children{
        display: flex ;
    }
</style>